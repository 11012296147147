import { myMixin } from '../../../mixins';
import { mapActions, mapState } from 'vuex'

export default {
  mixins: [myMixin],  
  data () {
        return {
            days_name: ['Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sábado'],  
            days_number: [2,3,4,5,6,7],
            renderComponent: true,
            showHours: false,
            modelTeste: 0,
            shifts: [],
            hourClasses: [],
            byDate: false,
            showCalendar: false,
            modelClasse: { start_date: null, end_date: null },
            stackUp: false,
            indexHover: -1,
            config: {
              allowShock: false,
              allowDoubleClass: false,
              allowDoubleClassJoin: false,
              allowDesloc: false,
              allowJourney: false,
              allowShockAmbience: false,
              allowHourGroup: false,
              allowIntraDay: false,
              allowMaxHoursDay: false,
              allowAvailability: false,
            },
            dialogChatGroup: false,
            loadingTeacher: false,
            loadingAmbience: false,
            loadingAvailability: false,
            progress: false,
            searchGroup: null,
            showSunday: false,
            showMailHourTeacher: false,
            selectedTeacher: {},
            isSelectCard: false,
            showReplicateForm: false,
            loading: false,
            pendencies: -1,
            showRegisterTeacher: false,
            selectedGroup: {},
            showRegisterGroup: false,
            showJoins: false,
            snackbar: false,
            error: { color: 'error', icon: null, title: null, text: null  },
            message: '',  
            visible: false,
            class: '',
            title: '',
            drawer: false,
            menu: true,
            historyTeachers: [],
            historyGroups: [],
            ambiences: [],
            selectedAmbience: null,
            selectedGrid: -1,
            groupJoins: [],
            join: null,
            movingGrid: null,
            newSchedule: false,
            which: null,
            groups: [],
            group: null,
            progressRefreshPendencies: false,
            progressClearClasses: false,
            hours: [],
            showFilterPrints: false,
            teacherShow: null,
            ambienceShow: null,
            dragging: false,
            classCell: {
              'todo-item': true,
              'trash-drop': false,
            },
            styleButton: {
              activeColor: 'green',
              width: "120px"
            },
            grids: [],
            selected: [],
            colors: ['error', 'success', 'primary', 'purple', 'pink', 'cyan', 'indigo', 'blue', 'teal', 'amber', 'blue-grey', 'light-green', 'orange', 'brown', 'light-blue', 'deep-purple']
        }
    },

    watch: {
      
      

    },

    computed: {
      ...mapState('auth', ['acesso', 'user']),

      isMaster(){
        return this.user.is_master;
      },

      isInternal(){
        return this.user.is_internal;
      },
      
      bg () {
        return this.background ? 'https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg' : undefined
      },

        
      },

    created(){
      this.showJoins = false;
    },
    mounted() {
        this.setTitle();
        this.loadHours();
    },

    watch: {
      
    },

    methods: {
        ...mapActions('schedule', ['ActionCreateClasse', 'ActionUpdateClasse', 'ActionDeleteClasse', 'ActionClearClasses']),
        ...mapActions('group', ['ActionFindGroupById', 'ActionFindGridsOptimized', 'ActionRefreshPendencies']),
        ...mapActions('join', ['ActionFindJoinByGroupId']),
        ...mapActions('hour', ['ActionGetHours']),
        ...mapActions('teacher', ['ActionFindTeacherClassesOptimized']),
        ...mapActions('ambience', ['ActionFindAmbienceClassesOptimized']),
        ...mapActions('availability', ['ActionFindOptimized']),
        
        refreshPendencies(){
          
          this.progressRefreshPendencies = true;
          
          this.ActionRefreshPendencies()
            .then((res) => {
                
            }).finally(() => {
              this.progressRefreshPendencies = false;
              
            });

        },

        clearClasses(){
          
          this.progressClearClasses = true;
          
          this.ActionClearClasses()
            .then((res) => {
                
            }).finally(() => {
              this.progressClearClasses = false;
              
            });

        },
        
        cancelSelection(val){
            if(val.button == 2){
                this.finishMoving(true, true);
            }
        },

        generateColor() {
          
          const letters = '0123456789ABCDEF';
          let color = '#';
          
          for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
          }
          
          return color;
          
        },

        getPosition(index){

          if(!this.stackUp){
            return null;
          }
          
          let top = 0;
          let left = 0;
          
          if(index > 0){
            top = -60 * index;
            left = (index * 8);
          }

          return 'position: relative; top: ' + top  + 'px; left: ' + left +'px';

        },

        cartToFront(ev, show, index = -1){
          
          this.indexHover = index;
          ev.target.style.zIndex = show ? 1 : null;
          
        },

        loadHours(){

          let params = {
            find_columns: {
              active: 1
            },
            with: 'groups'
          };
          
          this.ActionGetHours(params).then((res) => {
                
            this.hours = res.data;
            
          });
        },

        hasGroups(hour){

          var valid = false;
          
          if(this.group && this.group.hourGroups){
            
            if(this.hourClasses.includes(hour.id)){
              return true;
            }

            this.group.hourGroups.forEach(hour_group_id => {
              
              var find = hour.groups.find(h => h.id == hour_group_id);

              if(find != null){
                valid = true;
                return;
              }

            });

          }
          
          return valid;

        },

        disableHour(hour){

          if(this.selectedGrid == -1){
            return false;
          }
          
          if(this.group){
            
            var grid = this.group.grids[this.selectedGrid];
            
            if(grid && grid.hourGroup){
              var find = hour.groups.find(h => h.id == grid.hourGroup.id);
              return find == null;
            }
            
          }
          
          return false;

        },

        selectGroup(group, clearJoins, force = false){
          
          this.pendencies = -1;
          this.finishMoving(true, false);

          if(!force){
            if(!group || (this.group && this.group.id == group.id)){
              return;
            }
          }

          this.group = group;
          this.group.grids = [];
          this.progress = true;
          
          if(clearJoins){
            this.showJoins = false;
          }
         
          this.ActionFindGridsOptimized({ type: 'group', item_id: group.id })
            .then((res) => {
                
                var grids = res.data;
                this.sortGrids(grids);
                this.group.grids = grids;

                this.loadJoins(this.group);
                if(!this.isAnima()){
                  this.loadDates(this.group);
                }
                this.loadHistoryTeachers(this.group);
                this.loadHistoryGroups(this.group, !clearJoins);
                this.loadColors(this.group);
                this.loadShifts(this.group);
                this.loadHourGroups(this.group);
          }).finally(() => {

            Object.keys(this.config).forEach(v => this.config[v] = false);

            this.progress = false;
            this.forceRerender();
          });

        },

        sortGrids(grids){

          grids.forEach(grid => {
            grid.key = grid.discipline.name + '|' + grid.work_load_type + '|' + grid.group_number.toString().padStart(3, '0');
          });

          grids.sort(function(a, b){
            if(a.key > b.key){
              return 1;
            }else if(a.key < b.key){
              return -1;
            }else{
              return 0;
            }
          });

        },

        loadHourGroups(group){

          group.hourGroups = [];

          if(group.hour_group_id){
            group.hourGroups.push(group.hour_group_id);
          }

          this.group.grids.forEach(grid => {
            
            if(grid.hourGroup){
              if(!group.hourGroups.includes(grid.hourGroup.id)){
                group.hourGroups.push(grid.hourGroup.id);
              }
            }

          });

        },

        getShiftGroup(group){

          return group.time_shift_id ? group.time_shift_id : group.shift_id;

        },

        validateShift(hour, shift_id){

          if(this.group){
            
            let shift = this.getShiftGroup(this.group);
            
            if(shift == 4 || shift == 5){
              return this.hasGroups(hour);
            }
           
          }

          return this.shifts.includes(shift_id);

        },

        loadShifts(group){

          this.shifts = [];
          this.hourClasses = [];
          this.shifts.push(this.getShiftGroup(group));
          

          group.grids.forEach(grid => {
            grid.classes.forEach(classe => {
              let hour = this.hours.find(h => h.id == classe.hour_id);
              if(hour){
                
                if(!this.shifts.includes(hour.shift_id)){
                  this.shifts.push(hour.shift_id);
                }

                if(!this.hourClasses.includes(hour.id)){
                  this.hourClasses.push(hour.id);
                }

              }
            });
          })
        },

        loadColors(group){

          var index = 0;
          
          group.grids.forEach(grid => {
            
            if(index < this.colors.length -1){
              grid.color = this.colors[index];
            }else{
              grid.color = this.generateColor();
            }

            index++;

          });

        },
        
        setTitle(){
            this.title = "Horário de Aula";
        },

        completedNewGroup(group){
          
          this.showRegisterGroup = false;
          
          if(group){
            // Recarregar Lista de Turmas
          }
        },

        openDialogGroup(val){
          
          if(!val){
            this.selectedGroup = {};
            this.showRegisterGroup = true;
            return;
          }

          if(this.group && this.group.item){
            this.selectedGroup = this.group.item;
            this.showRegisterGroup = true;
            return;
          }
          
          this.progress = true;

          var payload = {
            id: val.id,
            showAllCampuses: true,
          };
          
          this.ActionFindGroupById(payload).then((res) => {
                
            if(res.success){
              this.group.item = res.data;
              this.selectedGroup = res.data;
              this.showRegisterGroup = true;
              
            }

            this.progress = false;
                
          });
          
        },

        openDialogTeacher(val){
          this.selectedTeacher = val;
        },

        loadJoins(group){

          var payload = {
            group_id: group.id,
            with: "grids.discipline,grids.group.course,grids.group.campus.hourGroups",
            showAllCampuses: true
          }

          this.ActionFindJoinByGroupId(payload).then((res) => {
            
            if(res.success){
              group.grids.forEach(grid => {
                if(grid.join){
                  var join = res.data.find(j => j.id == grid.join.join_id);
                  
                  if(join){
                    
                    grid.join.grids = join.grids;

                    grid.join.grids.forEach(g => {

                      if(g.group && !g.group.hour_group_id){
                        var find = g.group.campus.hour_groups.find(h => h.category_group_id == g.group.category_group_id);
                        
                        if(find){
                          g.group.hour_group_id = find.hour_group_id;
                        }else{
                          g.group.hour_group_id = g.group.campus.hour_group_id;
                        }

                      }

                    });

                  }
                }
              })
            }
          });

          
        },

        loadDates(group){

          var payload = {
            id: group.id,
            with: "grids.dates",
            showAllCampuses: true,
          }

          this.ActionFindGroupById(payload).then((res) => {
            if(res.success){
              group.grids.forEach(grid => {
                  var findGrid = res.data.grids.find(g => g.id == grid.id);
                  if(findGrid){
                    grid.dates = findGrid.dates;
                }
              })
            }
          });
        },

        async deleteSchedule(grid, classe){

          this.finishMoving(true, false);
          var ret = await this.deleteClasse(classe);

          if(ret){
            
            var find = grid.classes.find(c => c.id == classe.id);

            if(find){
              grid.classes.splice(grid.classes.indexOf(find), 1);
            }

            this.loadTeachersHours(grid.teacher);
            this.loadAmbiencesHours(grid.ambience);
            this.updatePendencies();

          }

          this.forceRerender();

        },

        lock(ev, day, hour){
          
          var classe = ev.grid.classes.find(c => (c.day_of_week_id == day && this.isHourEqual(e.hour, hour)));
          
          if(classe){
            classe.locked = ev.locked;
            this.save(ev.grid, classe);
          }
          
        },

        getSchedules(day, hour){
          
          var grid = this.getGrid(day, hour);

          if(!grid)
            return null;
          
          var schedule = grid.classes.find((s) =>
            (s.day_of_week_id == day && s.hour_id == hour.id)
          );

          return schedule;
        },

        getClassCard(day, hour){
          
          if(this.dragging){

            if(this.which.day == day && this.isHourEqual(this.which.hour, hour)){
              return 'ghost-card';
            }

          }

          return "";

        },

        getClassCell(day, hour){

          var disponivel = false;
          var outherGroups = false;
          var selected = false;

          if(this.which){
            if(this.which.day == day && this.isHourEqual(this.which.hour, hour)){
              selected = true;
            }
          }

          if(this.movingGrid){

              var disp = this.getAvailability(day, hour);
              
              if(this.movingGrid.teacher && this.movingGrid.teacher.classes){
                var classes = this.movingGrid.teacher.classes.find(c => 
                    (
                      c.day_of_week_id == day && 
                      this.isHourEqual({ id: c.hour_id, start: c.start, end: c.end }, hour) &&
                      c.group_id != this.movingGrid.group.id &&
                      (!c.join_id || !this.movingGrid.join || (c.join_id != this.movingGrid.join.join_id))
                    )
                  );
                
                

                if(classes){
                  outherGroups = true;
                }

              }

              if(disp){
                disponivel = true;
                //indisponivel = false;
              }else{
                disponivel = false;
                //indisponivel = true;
              }
          }
          
          var classCell = {
            disponivel: disponivel,
            indisponivel: outherGroups,
            'cell-selected' : selected,
            'ghost-card': false,
          };

          return classCell

        },

        getAvailability(day, hour){

          if(this.movingGrid && this.movingGrid.teacher && this.movingGrid.teacher.availability){
            
            var disp = this.movingGrid.teacher.availability.find((d) => (this.isHourEqual({ id: d.hour_id, start: d.start, end: d.end}, hour) && d.day_of_week_id == day));

            if(disp)
              return disp;
            else
              return null;

          }else{
            return null;
          }
        },

        isMoving(day, hour){

          if(this.movingGrid){
            return (this.movingGrid.day == day && this.movingGrid.hour == hour);
          }else
            return true;

        },

        dragEnter(ev) {
          /* 
          if (ev.clientY > ev.target.height / 2) {
            ev.target.style.marginBottom = '10px'
          } else {
            ev.target.style.marginTop = '10px'
          }
          */
        },

        dragLeave(ev) {
          /* 
          ev.target.style.marginTop = '2px'
          ev.target.style.marginBottom = '2px'
          */
        },
        dragEndCard(ev) {
          this.dragging = false;
          return;
        },
          
      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
      
      showGridInfo(grid, ambience, loadTeacher = true){
        
        this.showJoins = false;
        this.groupJoins = [];
        this.join = null;
        this.movingGrid = grid;

        if(grid && grid.join && grid.join.grids){
          this.showJoins = true;
          this.join = grid.join;
          grid.join.grids.forEach(grid => {
            if(grid.group){
              var itemGroup = {
                id:             grid.group.id, 
                name:           grid.group.name, 
                hour_group_id:  grid.group.hour_group_id,
                time_course:    grid.group.time_course,
                shift_id:       grid.group.shift_id,
                time_shift_id:  grid.group.time_shift_id,
                course:         grid.group.course,
                campus:         grid.group.campus,
              };
              this.groupJoins.push(itemGroup);
            }
          });
        }

        if(loadTeacher && grid && grid.teacher){
          this.loadTeachersHours(grid.teacher);
        }

        if(grid && ambience){
          this.loadAmbiencesHours(ambience);
        }

      },

      loadTeachersHours(teacher){
        
        if(!teacher){
          return;
        }

        this.loadAvailability(teacher);

        if(this.loadingTeacher){
          return;
        }

        this.loadingTeacher = true;

        this.ActionFindTeacherClassesOptimized({ id: teacher.id })
          .then(res => {
              this.teacherShow = null;
              var newObject = {};
              Object.assign(newObject, teacher);
              this.teacherShow = newObject;
              this.teacherShow.classes = res.data;
              teacher.classes = res.data;
          }).finally(() => {
            this.loadingTeacher = false;
            this.forceRerender();
          });
        
      },

      loadAmbiencesHours(ambience){
        
        if(!ambience){
          return;
        }

        if(this.loadingAmbience){
          return;
        }

        this.loadingAmbience = true;

        this.ActionFindAmbienceClassesOptimized({ id: ambience.id })
          .then(res => {
              this.ambienceShow = null;
              var newObject = {};
              Object.assign(newObject, ambience);
              this.ambienceShow = newObject;
              this.ambienceShow.classes = res.data;
              ambience.classes = res.data;
          }).finally(() => {
            this.loadingAmbience = false;
            this.forceRerender();
          });
        
      },

      loadAvailability(teacher){
        
        if(!teacher || teacher.availability){
          return;
        }

        this.loadingAvailability = true;

        this.ActionFindOptimized({ teacher_id: teacher.id })
          .then(res => {
            teacher.availability = res.data;
          }).finally(() => {
            this.loadingAvailability = false;
            this.forceRerender();
          });
        
      },

      forceRerender () {
        this.renderComponent = false;

        this.$nextTick(() => {
          this.renderComponent = true;
        });
      },

      loadHistoryTeachers(group){

        this.historyTeachers = [];
        group.grids.forEach((grid) => {
          if(grid.teacher){
            
            var contains = this.historyTeachers.find(t => t.id == grid.teacher.id);
            
            if(!contains){
              this.historyTeachers.push(grid.teacher);
            }
            
          }
          
        });

      },

      loadHistoryGroups(group, selectedByTree){

        var find = this.historyGroups.find(g => g.id == group.id);

        if(find){
          if(selectedByTree){
            this.historyGroups.splice(this.historyGroups.indexOf(find), 1);
            this.historyGroups.unshift(group);
          }
        }else{
          var count = this.historyGroups.length;
          if(count > 5){
            this.historyGroups.splice(count - 1, 1);
          }
          this.historyGroups.unshift(group);
        }
      },

      selectGridClick(day, hour){
        
        var grid = null;

        if(this.isSelectCard){
          this.isSelectCard = false;
          return;
        }

        if(this.selectedGrid == -1){
          
          if(this.which){
            this.moveSchedule({ day:  day, hour: hour });
          }

        }else{
          grid = this.group.grids[this.selectedGrid];
          this.insertClassFromList(grid, day, hour);
        }

      },

      saveByDate(classe){
        this.showCalendar = false;
        this.save(this.movingGrid, classe, true);
      },

      async insertClassFromList(grid, day, hour){
        
        if(!grid){
          return;
        }

        if(this.byDate){
          this.modelClasse = this.addItem(day, hour, false);
          this.showCalendar = true;
          return;
        }
        
        if(grid.pendencies <= 0){
          this.finishMoving(true, false);
          return;
        }

        this.movingGrid = grid;

        var oldClasses =  JSON.parse(JSON.stringify(this.movingGrid.classes));
        var newClasse = this.addItem(day, hour);

        var saved = await this.save(this.movingGrid, newClasse);
        
        if(!saved && this.movingGrid){
          this.movingGrid.classes = oldClasses;
        }else{
          this.updatePendencies();
        }

      },

      selectCard(grid, day, hour){
        
        if(this.selectedGrid != -1){
          return;
        }
        
        this.isSelectCard = true;

        this.which = {
          day: day,
          hour: hour
        };

        this.movingGrid = grid;

        var classes = grid.classes.filter(c => (c.day_of_week_id == day && c.hour_id == hour.id));

        if(classes && classes.length > 0){
          this.showGridInfo(grid, classes[0].ambience);
        }else{
          this.showGridInfo(grid, grid.ambience);
        }
        
      },

      getGrid(day, hour){

        if(!this.group)
          return null;

        var grid = null;

        if(this.movingGrid){
          grid = this.movingGrid;
          grid.day = day;
          grid.hour = hour;
        }

        return grid;
      },

      getGrids(day, hour){

        if(!this.group)
          return null;
        
        var grids = [];
        
        this.group.grids.forEach((g) => {

          g.classes.forEach((c) => {
            
            var grid = {};

            if(c.day_of_week_id == day && c.hour_id == hour.id){
              
              Object.assign(grid, g);
              grid.day        = day;
              grid.hour       = hour;
              grid.start_date = c.start_date;
              grid.end_date   = c.end_date;
              grids.push(grid);

            }
          })

        });

        return grids;
      },

      // Início de arrastar as aulas
      dragStart(which, ev) {
          
        if(ev.locked && ev.locked == true){
          ev.dataTransfer.dropEffect = 'none';
          return;
        }

        this.dragging = true;
        ev.dataTransfer.dropEffect = 'move';
        this.movingGrid = ev.grid;
        this.which = which;

      },

      getClasses(grid, from){

        var classes = grid.classes.find(c => (grid.start_date == c.start_date && c.day_of_week_id == from.day && c.hour_id == from.hour.id));
        
        if(classes){
          return classes;
        }else{
          return null;
        }

      },

      // fim ao arrastar as aulas
      dragFinish(to, ev) {
        this.moveSchedule(to);
      },

      async moveSchedule(to){
      
        if(!to || !to.day || !to.hour){
          return;
        }

        if(this.which && (this.which.day != to.day || this.which.hour.id != to.hour.id)){
          
          var oldClasse = {};
          Object.assign(oldClasse, this.getSchedules(this.which.day, this.which.hour));
          var newClasse = this.moveItem(this.which, to);

          var saved = await this.save(this.movingGrid, newClasse);
          
          if(!saved){
            newClasse.day_of_week_id = oldClasse.day_of_week_id;
            newClasse.hour_id        = oldClasse.hour_id;
            newClasse.hour           = oldClasse.hour;
          }
        }

        this.finishMoving(false, false);

    },

    addItem(day, hour, pushItem = true) {
            
      var schedule = {
        id:             0,
        grid_id:        this.movingGrid.id,
        day_of_week_id: day,
        hour_id:        hour.id,
        hour:           hour,
        locked:         0,
        ambience:       this.movingGrid.ambience,
        ambience_id:    this.movingGrid.ambience_id,
        start_date:     null,
        end_date:       null,
        teacher_id:     this.movingGrid.teacher_id
      };

      if(pushItem){
        this.movingGrid.classes.push(schedule);
      }

      return schedule;

    },

    moveItem(from, to) {
        
      if(!from || !from.day || !from.hour){
        return;
      }

      if(!to || !to.day || !to.hour){
        return;
      }
      
      var schedule = this.getSchedules(from.day, from.hour);
      
      if(schedule){
        
        schedule.day_of_week_id = to.day;
        schedule.hour_id        = to.hour.id;
        schedule.hour           = to.hour;

      }

      return schedule;

    },

    async save(grid, classe, pushClasse = false){

      if(this.loading){
        return;
      }

      if(!classe){
        return;
      }

      this.loading = true;
      this.error = {};

      var payload = {
        id:               classe.id,
        grid_id:          classe.grid_id,
        hour_id:          classe.hour_id,
        day_of_week_id:   classe.day_of_week_id,
        // locked:           classe.locked ? classe.locked : 0,
        ambience_id:      grid.ambience ? grid.ambience.id : null,
        start_date:       classe.start_date,
        end_date:         classe.end_date,
        config:           this.config,
        showAllCampuses:  true,
      };

      try{
        
        var res;

        if(classe.id == 0){
          res = await this.ActionCreateClasse(payload);
        }else{
          res = await this.ActionUpdateClasse(payload);
        }

        if(res.success){
          
          if(classe.id == 0){
            classe.id = res.data.id;
          }
          this.loadTeachersHours(grid.teacher);
          this.loadAmbiencesHours(grid.ambience);

          if(pushClasse){
            this.movingGrid.classes.push(classe);
          }

          return true;
        }else{
          return false;
        }

      } catch(error) {
          
          if(error.errors){
            this.error = error.errors;
          }else if(error.message){
            this.error.message = error.message;
          }else{
            this.error.message = 'Erro ao salvar a aula!';
          }
  
          this.snackbar = true;
          return false;

      } finally {
        this.loading = false;
        this.forceRerender();
      }

    },

    updatePendencies(){
      
      let countWorkLoads = 0;
      let countTimes = 0;
      let pendencies = 0;

      this.group.grids.forEach(grid => {
        
        countWorkLoads += grid.work_load_time_table;
        
        grid.classes.forEach(classe => {
          
          let hour = this.hours.find(h => h.id == classe.hour_id);
       
          if(hour && !classe.start_date){
            countTimes += hour.number_of_classes;
          }
        });
      });

      pendencies = countWorkLoads - countTimes;

      if(pendencies > 0){
        this.pendencies = pendencies;
      }else{
        this.pendencies = 0;
      }
      
    },

    async deleteClasse(classe){

      if(this.loading){
        return;
      }
      
      this.loading = true;

      try{
        
        var res = await this.ActionDeleteClasse({id: classe.id});
        
        if(res.success){
          return true;
        }

      } catch(error){
        
        this.colorAlert = 'error';

        if(error.errors){
          this.error = error.errors;
        }else if(error.message){
          this.error.message = error.message;
        }else{
          this.error.message = 'Erro ao excluir a aula!';
        }
  
        this.snackbar = true;
        return false;

      } finally {
        this.loading = false;
      }
        

    },

    finishMoving(clearList, clearJoins){
      
      if(clearList){
        this.selectedGrid = -1;
      }

      if(clearJoins){
        this.showJoins = false;
        this.groupJoins = [];
        this.join = [];
      }
      
      this.movingGrid = null;
      this.newSchedule = false;
      this.dragging = false;
      this.which = null;

    },

      
  },

}